import { DefaultButton, TooltipHost, ITooltipHostStyles } from '@fluentui/react';
import React from 'react';
import { useId } from '@fluentui/react-hooks';

import { toolTipClose } from '../../../utilities/Constants';
import CopilotLogo from '../../CopilotLogo/CopilotLogo';

import './CopilotButton.scss';

const CopilotButton = ({ onClick }: { onClick: () => void }) => {
  const tooltipId = useId('tooltip');
  const calloutProps = { gapSpace: 0 };
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'flex' } };

  return (
    <TooltipHost content={toolTipClose} id={tooltipId} calloutProps={calloutProps} styles={hostStyles}>
      <DefaultButton className="copilotButton" onClick={onClick} aria-describedby={tooltipId}>
        <CopilotLogo />
      </DefaultButton>
    </TooltipHost>
  );
};

export default CopilotButton;
