import React from 'react';
import { Image } from '@fluentui/react';

import CopilotSvg from '../../assets/images/ESILearningAssistant.svg';

const CopilotLogo = () => {
  return (
    <>
      <Image src={CopilotSvg} alt="click to open assist" className="copilotIcon" />
    </>
  );
};

export default CopilotLogo;
